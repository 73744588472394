<template>
    <div></div>
</template>

<script>
export default {
    created() {
        window.location.href = "https://calendly.com/geteflex/15min"
    }
}
</script>